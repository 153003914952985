import React, { useEffect, useRef } from "react"
import { useLocation } from "@reach/router"
import Countdown from "react-countdown"
import Popup from "reactjs-popup"
import "reactjs-popup/dist/index.css"
import Star from "../../../../images/svg/star-popup.svg"
import StarIcon from "../../../../images/svg/star-icon.svg"
import Square from "../../../../images/svg/cuadro.svg"
import {
  convertStringToTime,
  getCodes,
  getActionByType,
  getCodeFromType,
} from "../../../../helpers/helper.actions"
import { informCustomEvent } from "../../../../helpers/helper.analytics"

const CounterPopup = ({
  linkTo,
  linkTxt,
  title,
  text,
  showOn,
  band,
  eventDate,
  utmValue,
  small_type,
  small_section,
  name,
  actions,
  properties,
  property,
  developments,
  development,
}) => {
  const location = useLocation()
  const pathname = location.pathname.replaceAll("/", "")

  // Timeout to show the component
  useEffect(() => {
    if (checkPage()) {
      if (
        (showOn === "Ficha de emprendimiento" && development?.id) ||
        (showOn === "Ficha de la propiedad" && property?.id) ||
        (showOn !== "Ficha de emprendimiento" &&
          showOn !== "Ficha de la propiedad")
      ) {
        informCustomEvent("SHOW_" + getCodes(small_type, showOn) + "_" + name)
        setTimeout(() => {
          pop.current.open()
        }, 1000)
      }
    }
  }, [pathname, property, development])

  // Check where shows
  const checkPage = () => {
    if (developments.length > 0 || properties.length > 0) {
      //Si es para una ficha en particular
      if (
        existTimerPopDetail(property?.id) ||
        existTimerPopDetail(development?.id)
      ) {
        if (
          properties.find(
            prop => prop?.toString() === property.id?.toString()
          ) ||
          developments.find(
            dev => dev?.toString() === development.id?.toString()
          )
        ) {
          return true
        }
      }
      return false
    } else {
      if (pathname === "" && showOn.toLowerCase() === "inicio") {
        return true
      }
      if (
        pathname.toLowerCase().includes("emprendimientos") &&
        pathname.length > 18 &&
        showOn.toLowerCase() === "ficha de emprendimiento" &&
        !existTimerPopDetail(development?.id)
      ) {
        return true
      }
      if (
        pathname.toLowerCase().includes("propiedad") &&
        showOn.toLowerCase() === "ficha de la propiedad" &&
        !existTimerPopDetail(property?.id)
      ) {
        return true
      }
      if (
        pathname.toLowerCase() === "venta" &&
        showOn.toLowerCase() === "resultados venta"
      ) {
        return true
      }
      if (
        pathname.toLowerCase() === "alquiler" &&
        showOn.toLowerCase() === "resultados alquiler"
      ) {
        return true
      }
      if (
        pathname.toLowerCase() === "alquiler-temporario" &&
        showOn.toLowerCase() === "resultados alquiler temporario"
      ) {
        return true
      }
      return Array.isArray(showOn)
        ? showOn.toLowerCase().includes(pathname.toLowerCase())
        : pathname.toLowerCase() === showOn.toLowerCase() || false
    }
  }

  const existTimerPopDetail = id => {
    const TimerPop = [
      ...getActionByType(actions, getCodeFromType("PopUp Vertical")),
      ...getActionByType(actions, getCodeFromType("PopUp Cuadrado")),
      ...getActionByType(actions, getCodeFromType("PopUp Timer")),
      ...getActionByType(actions, getCodeFromType("PopUp Panoramico")),
    ]
    if (id) {
      for (const singleSquare of TimerPop) {
        for (const dev of singleSquare.developments) {
          if (dev.toString() === id.toString()) {
            return true
          }
        }
        for (const prop of singleSquare.properties) {
          if (prop.toString() === id.toString()) {
            return true
          }
        }
      }
      return false
    } else {
      return false
    }
  }

  // Render countdown
  const rendererCtd = ({ days, hours, minutes, seconds }) => (
    <div className="counter d-flex justify-content-center text-center mt-4">
      <div className="mx-2 d-flex flex-column justify-content-center">
        <span className="date-val">{days}</span>
        <span className="date-text">DÍAS</span>
      </div>
      <div className="me-2 d-flex flex-column justify-content-center">
        <span className="date-val">{hours}</span>
        <span className="date-text">HS</span>
      </div>
      <div className="me-2 d-flex flex-column justify-content-center">
        <span className="date-val">{minutes}</span>
        <span className="date-text">MIN</span>
      </div>
      <div className="me-2 d-flex flex-column justify-content-center">
        <span className="date-val">{seconds}</span>
        <span className="date-text">SEG</span>
      </div>
    </div>
  )

  const pop = useRef()

  return checkPage() ? (
    <Popup ref={pop} position="right center">
      <i
        className="icon-plus close"
        type="button"
        role="button"
        id="closePopup"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={() => pop.current.close()}
      ></i>
      {/* <button className="close" > */}
      {/* &times; */}
      {/* </button> */}
      <div className={`content-border ${band ? "show-band" : "hide-band"}`}>
        <Square className={`square-content ${band ? "show" : "hide"}`} />
        {band && (
          <div className="band">
            <span className="text-band text-center">{band}</span>
          </div>
        )}
        <div className="star-icon">
          <StarIcon className="star-icon-svg" />
          {/* <i className="icon-star"></i> */}
        </div>
        <h1 className="text-center text-uppercase">{title}</h1>
        {text && <p className="popup-text my-3 text-center">{text}</p>}
        <Countdown
          date={new Date(eventDate).getTime()}
          renderer={rendererCtd}
        />
         
        {linkTo && (
          <a
            className="d-flex text-center align-items-center justify-content-center text-uppercase"
            onClick={() =>
              pop.current.close() +
              informCustomEvent(
                "PUSH_" + getCodes(small_type, showOn) + "_" + name
              )
            }
            href={linkTo + utmValue}
            target="_blank"
          >
            {linkTxt}
          </a>
        )}
      </div>
    </Popup>
  ) : null
}

export default CounterPopup

// Component usage
// <CounterPopup
//      linkTo={'/servicios'} -> url link
//      linkTxt={'A un click de conocerlo'} -> text link
//      title={'¡Nuevo emprendimiento!'} -> text title
//      showOn={'nosotros'} -> where show | add to components page
//      band={'Proyecto de pozo, texto de prueba'} -> band top right
//      eventDate={'9/15/2022'}/> -> event date
