export const getActionByPage = (actions, page) => {
  if (actions) {
    return actions.filter(
      action => action?.section?.toLowerCase() === page?.toLowerCase()
    )
  }
  return []
}

export const getActionByType = (actions, type) => {
  if (actions) {
    return actions.filter(
      action => action?.type?.toLowerCase() === type?.toLowerCase()
    )
  }
  return []
}

export const convertStringToTime = string => {
  const date = string.split("T")[0]
  const splitDate = date.split("-")
  const formateDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0]
  return formateDate
}

export const removeExpiratedActions = actions => {
  const today = new Date()
  const filtered = []
  for (let index = 0; index < actions.length; index++) {
    const element = actions[index]
    if (new Date(element.date_end) > today || element.date_end === null) {
      filtered.push(element)
    }
  }
  return filtered
}

// SECCIONES:

// "INI", "Inicio"
// "EMP", "Emprendimientos"
// "FDE", "Ficha de emprendimiento"
// "FDP", "Ficha de la propiedad"
// "RVE", "Resultados Venta"
// "RAL", "Resultados Alquiler"
// "RAT", "Resultados Alquiler Temporario"
// "TAS", "Tasaciones"
// "NOV", "Novedades"
// "CON", "Contacto"
// "NOS", "Nosotros"

export const getCodes = (action, page) => {
  let code = ""
  switch (page) {
    case "Inicio":
      code += "INI_"
      break
    case "Emprendimientos":
      code += "EMP_"
      break
    case "Ficha de emprendimiento":
      code += "FDE_"
      break
    case "Ficha de la propiedad":
      code += "FDP_"
      break
    case "Resultados Venta":
      code += "RVE_"
      break
    case "Resultados Alquiler":
      code += "RAL_"
      break
    case "Resultados Alquiler Temporario":
      code += "RAT_"
      break
    case "Tasaciones":
      code += "TAS_"
      break
    case "Novedades":
      code += "NOV_"
      break
    case "Contacto":
      code += "CON_"
      break
    case "Nosotros":
      code += "NOS_"
      break
    default:
      code += page?.toUpperCase() + "_"
  }
  switch (action) {
    case "Video Encabezado":
      code += "VEN"
      break
    case "Imagen Encabezado":
      code += "IEN"
      break
    case "Slider de Imagenes Encabezado":
      code += "SIE"
      break
    case "PopUp Cuadrado":
      code += "PUC"
      break
    case "PopUp Vertical":
      code += "PUV"
      break
    case "PopUp Panoramico":
      code += "PUP"
      break
    case "PopUp Timer":
      code += "PUT"
      break
    case "Barra Encabezado":
      code += "BEN"
      break
    case "Barra Encabezado Timer":
      code += "BET"
      break
    case "Solapa Flotante":
      code += "SFL"
      break
    default:
      code += action
  }
  return code
}

export const getPageCodes = page => {
  let code = ""
  switch (page) {
    case "Inicio":
      code += "INI"
      break
    case "Emprendimientos":
      code += "EMP"
      break
    case "Ficha de emprendimiento":
      code += "FDE"
      break
    case "Ficha de la propiedad":
      code += "FDP"
      break
    case "Resultados Venta":
      code += "RVE"
      break
    case "Resultados Alquiler":
      code += "RAL"
      break
    case "Resultados Alquiler Temporario":
      code += "RAT"
      break
    case "Tasaciones":
      code += "TAS"
      break
    case "Novedades":
      code += "NOV"
      break
    case "Contacto":
      code += "CON"
      break
    case "Nosotros":
      code += "NOS"
      break
    default:
      code += page?.toUpperCase()
  }
  return code
}

export const getCodeFromType = action => {
  let code = ""
  switch (action) {
    case "Video Encabezado":
      code += "VEN"
      break
    case "Imagen Encabezado":
      code += "IEN"
      break
    case "Slider de Imagenes Encabezado":
      code += "SIE"
      break
    case "PopUp Cuadrado":
      code += "PUC"
      break
    case "PopUp Vertical":
      code += "PUV"
      break
    case "PopUp Panoramico":
      code += "PUP"
      break
    case "PopUp Timer":
      code += "PUT"
      break
    case "Barra Encabezado":
      code += "BEN"
      break
    case "Barra Encabezado Timer":
      code += "BET"
      break
    case "Solapa Flotante":
      code += "SFL"
      break
    default:
      code += action
  }
  return code
}

export const getSectionFromCode = page => {
  let code = ""
  switch (page) {
    case "INI":
      code += "Inicio"
      break
    case "EMP":
      code += "Emprendimientos"
      break
    case "FDE":
      code += "Ficha de emprendimiento"
      break
    case "FDP":
      code += "Ficha de la propiedad"
      break
    case "RVE":
      code += "Resultados Venta"
      break
    case "RAL":
      code += "Resultados Alquiler"
      break
    case "RAT":
      code += "Resultados Alquiler Temporario"
      break
    case "TAS":
      code += "Tasaciones"
      break
    case "NOV":
      code += "Novedades"
      break
    case "CON":
      code += "Contacto"
      break
    case "NOS":
      code += "Nosotros"
      break
    default:
      code += page.toUpperCase() + "_"
  }
  return code
}
export const getTypeFromCode = action => {
  let code = ""
  switch (action) {
    case "VEN":
      code += "Video Encabezado"
      break
    case "IEN":
      code += "Imagen Encabezado"
      break
    case "SIE":
      code += "Slider de Imagenes Encabezado"
      break
    case "PUC":
      code += "PopUp Cuadrado"
      break
    case "PUV":
      code += "PopUp Vertical"
      break
    case "PUP":
      code += "PopUp Panoramico"
      break
    case "PUT":
      code += "PopUp Timer"
      break
    case "BEN":
      code += "Barra Encabezado"
      break
    case "BET":
      code += "Barra Encabezado Timer"
      break
    case "SFL":
      code += "Solapa Flotante"
      break
    default:
      code += action
  }
  return code
}
