import { Link } from "gatsby"
import $ from 'jquery'
import React, {useState, useEffect} from "react"
import { useLocation } from "@reach/router";
import { connect } from "react-redux";
import {cleanDevelopmentAction} from "../redux/developmentsDucks";
import {clearPropertiesAction, clearPropertyAction, getAllLocationsAction} from "../redux/propertiesDucks";
import {getfavorites} from "../helpers/helper.favorites";
import ReactGA from 'react-ga';
import NavMenu from "./menu-nav";
import toast, { Toaster } from 'react-hot-toast';
import LOGO_NORMAL from "../images/svg/laura-farias-logo.svg";
import LOGO_WHITE from "../images/svg/laura-farias-logo-white.svg";

function Main({settings, updaterFavorites, allLocations, api_key, dispatch}) {

    const [open,setOpen] = useState(false);
    const location = useLocation()
    const pathname = location.pathname.split("/")[1]

    ReactGA.initialize('UA-206895121-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    const isResultTrue = () => {
        if(pathname.includes('venta') || pathname.includes('alquiler')){
            return true;
        }
        return false
    }

    useEffect(() => {
        if(!location.pathname.includes('/emprendimientos')){
            // dispatch(cleanDevelopmentAction())
        }
    },[location])

    const staticBody = () => {
        var body = document.body;
        body.classList.toggle("overflow-hidden");
    }

    const Compare = true;
    const [countFavorites,setCountFavorites] = useState(getfavorites("prop",settings?.short_name).length)

    useEffect(() => {
        if(!location.pathname.includes('/emprendimientos')){
            dispatch(cleanDevelopmentAction())
        }
        if(!location.pathname.includes('/propiedad')){
            dispatch(clearPropertyAction())
        }
        dispatch(clearPropertiesAction())
        setCountFavorites(getfavorites("prop",settings.short_name).length)
    },[location])

    useEffect(() => {
        if(api_key && !allLocations?.locations){
            dispatch(getAllLocationsAction())
        }
    },[api_key, allLocations])

    useEffect(() => {
        setCountFavorites(getfavorites("prop",settings.short_name).length)
        $("#count_favorites").removeClass("animate__heartBeat");
        $("#count_favorites_mobile").removeClass("animate__heartBeat");
        setTimeout(function(){
            $("#count_favorites").addClass("animate__heartBeat");
            $("#count_favorites_mobile").addClass("animate__heartBeat");
        }, 100);
        // $("#count_favorites").toggleClass("animate__heartBeat");
    },[updaterFavorites])

    return (
    <header id="header" className={"blur " + (open ? 'opened ' : '')}>
        <Toaster
        toastOptions={{
            className: 'toast-className',
            style: {
                maxWidth: 'fit-content',
                
              },
        }} />
        <nav className={"brand " + (isResultTrue() ? 'out-blur ' : '')}>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center">
                    <div className="col-3 d-flex d-lg-none align-items-center justify-content-lg-center">
                        <Link to="/favoritos" className="me-4 icon-favorites d-flex align-items-center justify-content-center">
                            <i className={"icon-like " + (location.pathname.includes("favoritos") ? 'active' : '')}></i>
                            <span id="count_favorites-mobile" className={'font-novecento ms-2 d-block d-lg-none ' + " animate__animated pt-1"}>{countFavorites}</span>
                        </Link>
                    </div>
                    <div className="col-lg-9 col-6 d-flex justify-content-center justify-content-lg-start">
                        <Link to={"/"} className="logo d-none d-lg-block">
                            <LOGO_NORMAL className="img color" />
                            <LOGO_WHITE className="img white" />
                        </Link>
                        <Link to={"/"}  className="logo d-lg-none">
                            <LOGO_NORMAL className="img color" />
                            <LOGO_WHITE className="img white" />
                        </Link>
                    </div>
                    <div className="col-3 text-right d-flex align-items-center justify-content-end">
                        <div className={"me-4 compare-link-wrapper " + (Compare ? "d-lg-block d-none" : 'd-none')}>
                        {(countFavorites === 0
                            ?  <div onClick={() => toast.error("No tienes Propiedades favoritas seleccionadas.")} className="font-novecento compare-link ms-4 tr-color">
                                    COMPARAR
                                </div>  
                            : (countFavorites === 1
                                ?  <div onClick={() => toast.error("Tienes que tener al menos 2 propiedades en favoritos para poder comparar")} className="font-novecento compare-link ms-4 tr-color">
                                    COMPARAR
                                  </div> 
                                : <Link to="/favoritos?comparar" className="font-novecento compare-link ms-4">
                                    COMPARAR
                                  </Link>))}
                        </div>
                        {(countFavorites === 0
                        ? <div className="hover-like d-lg-flex d-none align-items-center" 
                                    onClick={() => toast.error("No tienes Propiedades favoritas seleccionadas.")}>
                                <i className={`me-4 icon-like ${location.pathname.includes("favoritos") ? 'active' : ''} `  + (countFavorites < 1 && ' disabled ')}></i>
                            </div> 
                        : <div className={"hover-like d-lg-flex d-none align-items-center "}>
                                <span id="count_favorites" className={'' + (!countFavorites ? ' d-none ' : ' d-none d-lg-block ') + " animate__animated me-2 font-novecento" }>{countFavorites}</span> 
                                <Link className="me-4 icon-favorites d-none d-lg-flex align-items-center justify-content-center" to="/favoritos">
                                    <i className={`icon-like ${location.pathname.includes("favoritos") ? 'active' : ''} `  + (countFavorites < 1 && ' disabled ')}></i></Link>
                            </div>)}
                        <button id="toggle-nav" onClick={() => setOpen(!open) + staticBody()}>
                            <div className="d-flex align-items-center"> 
                                <span className="status_nav d-none d-lg-inline">Menú </span> 
                                <span className="ms-4"><i></i><i></i><i></i></span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </nav>
        <NavMenu />
  </header>
)}

export default connect(state => ({
    settings: state.settings,
    api_key: state.settings.keys.tokko,
    allLocations: state.properties.allLocationFilters,
    updaterFavorites: state.properties.updaterFavorites
}),null)(Main);