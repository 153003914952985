import {Link } from 'gatsby';
import React, {useEffect} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

import {connect} from 'react-redux';

import Image from "./Image";
import {REDUX_UPDATE_FILTERS, getTotalListFilters} from '../redux/propertiesDucks';
import {getDevelopmentsAction} from '../redux/developmentsDucks';
import { getNoveltiesAction } from '../redux/noveltiesDucks';

import {existOperation,existDevelopment} from '../helpers/helper.rendering'
import {getOperationView} from '../helpers/helper.filters'
import { getActionsAction } from '../redux/actionsDucks';
import { useGetDevelopmentsQuery } from '../redux/middlewareTokkoApi/developments';
import { useGetBlogsQuery } from '../redux/mediacore/blog';
import { useGetTotalFiltersQuery } from '../redux/originalTokkoApi/properties';
import { useGetJobsQuery } from '../redux/mediacore/jobs';

const MenuNav = ({filters,curFilters,api_key,developments,novelties,dispatch}) => {
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              global_email
              keys{
                tokko
              }
              social{
                facebook
                instagram
                youtube
              }
              branch_office{
                name
                address
                city
                region
                broker_id
                contact {
                  phone
                  mail
                  whatsapp
                }
              }
              sections {
                  footer {
                      rows {
                      title_contact
                      button_contact {
                        value
                        link
                      }
                      title_office
                      title_social
                      title_newsletter
                    }
                  }
              }
          }
    }`)

    const location = useLocation()
    const pathname = location.pathname.split("/")[1]

    const { data: allTokkoFilterData, isLoading: isLoadingAllTokkoFilters } = useGetTotalFiltersQuery({"API_KEY":realEstate.keys.tokko});
    const { data: allDevelopmentsData } = useGetDevelopmentsQuery(realEstate.keys.tokko);
    const { data: allBlogData } = useGetBlogsQuery(6);
    const { data: allJobsData } = useGetJobsQuery(6);

    useEffect(() => {
        if(api_key){
            // dispatch(getDevelopmentsAction())
            // dispatch(getTotalListFilters())
        }
    }, [api_key])
    
    const staticBody = () => {
        var body = document.body;
        body.classList.remove("overflow-hidden");
    }


    
    const isResultView = () => {
        if(pathname.includes('venta') || pathname.includes('alquiler')){
            return true;
        }
        return false
    }

    const fillFilters = (item) => {
        return {
            location: [],
            type: [],
            operation: item === 'alquiler-temporario' ? 'alquiler-temporario' : item.toLowerCase(),
            environments:'',
            bedrooms:'',
            address:'',
            price:{
              type:'',
              priceMin:'',
              priceMax:''
            },
            surface:{
              type:'',
              surfaceMin:"",
              surfaceMax:"",
            },
            environments_types: [],
            generals: [],
            services: [],
            specials: [],
          }
    }

    useEffect(() => {
    },[])

    return(
        <nav className="nav-content opened d-flex align-items-center">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-8 px-xl-5">
                        <ul className="nav-menu">
                            <li className={(pathname === '' ? 'active ' : '')}><Link onClick={() => staticBody()} className="" to="/">HOME <span>01</span></Link></li>
                            {existDevelopment(allDevelopmentsData) ? <li className={(pathname === 'emprendimientos' ? 'active ' : '')}><Link  onClick={() => staticBody()} className="" to="/emprendimientos">EMPRENDIMIENTOS<span>02</span></Link></li>:''}
                            {existOperation("venta",allTokkoFilterData) ? <li className={(getOperationView(curFilters) === 'venta' && isResultView()  ? 'active ' : '')}><Link  onClick={() => staticBody() + dispatch(REDUX_UPDATE_FILTERS(fillFilters('venta')))} className="" to="/venta">VENTA<span>03</span></Link></li>:''}
                            {existOperation("alquiler",allTokkoFilterData) ? <li className={(getOperationView(curFilters) === 'alquiler' && isResultView() ? 'active ' : '')}><Link  onClick={() => staticBody() + dispatch(REDUX_UPDATE_FILTERS(fillFilters('alquiler')))} className="" to="/alquiler">ALQUILER<span>04</span></Link></li>:''}
                            <li className={(pathname === 'servicios' ? 'active ' : '')}><Link  onClick={() => staticBody()} className="" to="/servicios">SERVICIOS<span>05</span></Link></li>
                            <li className={(allBlogData?.news?.length === 0 && ' d-none ') + (pathname === 'novedades' ? 'active ' : '')}><Link  onClick={() => staticBody()} className="" to="/novedades">NOVEDADES<span>06</span></Link></li>
                            <li className={(pathname === 'nosotros' ? 'active ' : '')}><Link  onClick={() => staticBody()} className="" to="/nosotros">NOSOTROS<span>{(allBlogData?.news?.length === 0 ? '06' : '07')}</span></Link></li>
                            <li className={(allJobsData?.length === 0 && ' d-none ') + (pathname === 'rrhh' ? 'active ' : '')}><Link  onClick={() => staticBody()} className="" to="/rrhh">RRHH<span>{(allBlogData?.news?.length === 0 ? '07' : '08')}</span></Link></li>
                            <li className={(pathname === 'contacto' ? 'active ' : '')}><Link  onClick={() => staticBody()} className="mb-0" to="/contacto">CONTACTO<span>{(allJobsData?.length === 0 ? (allBlogData?.news?.length === 0 ? '07' : '08') : (allBlogData?.news?.length === 0 ? '08' : '09'))}</span></Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-4 px-xl-5 contact-nav">
                        <div className="row">
                            <div class="item-contact col-8 col-lg-12">
                                <h5>{realEstate.sections.footer.rows.title_contact}</h5>
                                <a class="hover-service-link" target="_blank" href={"mailto:" + realEstate.global_email}>{realEstate.global_email}</a>
                            </div>
                            <div class="item-contact d-none d-lg-block col-12">
                                <h5>{realEstate.sections.footer.rows.title_office}{realEstate.branch_office[0].name}</h5>
                                <p >{realEstate.branch_office[0].address}</p>
                                <p >{realEstate.branch_office[0].city}</p>
                                <p >{realEstate.branch_office[0].region}</p>
                                <p >Teléfono {realEstate.branch_office[0].contact.phone}</p>
                                <p >Whatsapp {realEstate.branch_office[0].contact.whatsapp}</p>
                                <p >Matrícula {realEstate.branch_office[0].broker_id}</p>
                            </div>
                            <div class="item-contact d-none d-lg-block col-12">
                                <h5>{realEstate.sections.footer.rows.title_office}{realEstate.branch_office[1].name}</h5>
                                <p >{realEstate.branch_office[1].address}</p>
                                <p >{realEstate.branch_office[1].city}</p>
                                <p >{realEstate.branch_office[1].region}</p>
                                <p >Teléfono {realEstate.branch_office[1].contact.phone}</p>
                                <p >Whatsapp {realEstate.branch_office[1].contact.whatsapp}</p>
                                <p >Matrícula {realEstate.branch_office[1].broker_id}</p>
                            </div>
                            <div class="item-contact social col-4 col-lg-4">
                                <h5>{realEstate.sections.footer.rows.title_social}</h5> 
                                <a target="_blank" href={realEstate.social.instagram} class="d-inline"><i class="icon-instagram1"></i></a>
                                <a target="_blank" href={realEstate.social.facebook} class="d-inline"><i class="icon-facebook1"></i></a>
                                <a target="_blank" href={realEstate.social.youtube} class="d-inline"><i class="icon-youtube"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default connect(state => ({
    curFilters:state.properties.filters,
}),null)(MenuNav);