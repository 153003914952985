import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { connect } from "react-redux"

import Header from "./header"
import Footer from "./footer"
import { useEffect } from "react"

import {CHANGE_APIKEY_ACTION} from '../redux/settingsDucks'
import Modules from "./modules"

const isBrowser = typeof window !== "undefined"

const Layout = ({ dispatch,children,preloader=false,property={},development={} }) => {
  
  const data = useStaticQuery(graphql`
    query LayoutInfoQuery {
      realEstate {
        seo{
          title
        }
        colors {
          primaryColor
          secondaryColor
          tertiaryColor
        }
        keys {
          tokko
        }
      }
    }
  `)


  useEffect(() => {
    dispatch(CHANGE_APIKEY_ACTION(data.realEstate.keys.tokko))
  },[])

  const body = isBrowser ? document.body : null
  const loader = isBrowser ? document.getElementById('preloader') : null

  useEffect(() => {
    setTimeout(() => {
      loader?.classList.add('preloader-hide')
    setTimeout(() => {
      body.classList.remove('preloader_active')
      body.classList.add('preloader_ready')
    }, preloader ? 300 : 0); 
    },(preloader ? 300 : 0));
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <style>
        {`\
          :root{\
            --primaryColor: ${data.realEstate.colors.primaryColor};\
            --secondaryColor: ${data.realEstate.colors.secondaryColor};\
            --tertiaryColor: ${data.realEstate.colors.tertiaryColor};\
          }\
        `}
        </style>
      </Helmet>
      <div>
        <Modules property={property} development={development} />
        {isBrowser 
          ? <Header siteTitle={data.realEstate.seo?.title || `Title`} /> 
          :''
        }
        <main>{children}</main>
        {isBrowser 
          ? <Footer /> 
          : ''
        }
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default connect(state => ({
}),null)(Layout);
