import React, { useEffect } from "react"
import { useLocation } from "@reach/router"
import { informCustomEvent } from "../../../../helpers/helper.analytics"
import {
  getCodes,
  getActionByType,
  getCodeFromType,
} from "../../../../helpers/helper.actions"

const SquarePopup = ({
  align,
  flat,
  linkTo,
  showOn,
  image,
  utmValue,
  small_type,
  small_section,
  name,
  actions,
  properties,
  property,
  developments,
  development,
}) => {
  const location = useLocation()
  const pathname = location.pathname.replaceAll("/", "")

  // Timeout to show the component
  useEffect(() => {
    if (checkPage()) {
      if (
        (showOn === "Ficha de emprendimiento" && development?.id) ||
        (showOn === "Ficha de la propiedad" && property?.id) ||
        (showOn !== "Ficha de emprendimiento" &&
          showOn !== "Ficha de la propiedad")
      ) {
        informCustomEvent("SHOW_" + getCodes(small_type, showOn) + "_" + name)
        setTimeout(() => {
          document.getElementById("squarePopUpBtn")?.click()
        }, 1000)
      }
    }
  }, [pathname, property, development])

  // Check align
  const checkAlign = () => {
    return align.toLowerCase() === "left"
      ? "align-left"
      : align.toLowerCase() === "right"
      ? "align-right"
      : align.toLowerCase() === "center"
      ? "align-center"
      : ""
  }

  // Limit the height
  const checkFlat = () => {
    return flat ? "flat-width" : ""
  }

  // Go to link, disable popup
  const goTo = () => {
    document.getElementById("closePopup").click()
    if (linkTo) {
      informCustomEvent("PUSH_" + getCodes(small_type, showOn) + "_" + name)
      window.open(linkTo + utmValue, "_blank", "noopener,noreferrer")
    }
  }

  // Check where shows
  const checkPage = () => {
    if (developments.length > 0 || properties.length > 0) {
      //Si es para una ficha en particular
      if (
        existSquarePopDetail(property?.id) ||
        existSquarePopDetail(development?.id)
      ) {
        if (
          properties.find(
            prop => prop?.toString() === property.id?.toString()
          ) ||
          developments.find(
            dev => dev?.toString() === development.id?.toString()
          )
        ) {
          return true
        }
      }
      return false
    } else {
      if (pathname === "" && showOn.toLowerCase() === "inicio") {
        return true
      }
      if (
        pathname.toLowerCase().includes("emprendimientos") &&
        pathname.length > 18 &&
        showOn.toLowerCase() === "ficha de emprendimiento" &&
        !existSquarePopDetail(development?.id)
      ) {
        console.log("true")
        return true
      }
      if (
        pathname.toLowerCase().includes("propiedad") &&
        showOn.toLowerCase() === "ficha de la propiedad" &&
        !existSquarePopDetail(property?.id)
      ) {
        console.log("true")
        return true
      }
      if (
        pathname.toLowerCase() === "venta" &&
        showOn.toLowerCase() === "resultados venta"
      ) {
        console.log("true")
        return true
      }
      if (
        pathname.toLowerCase() === "alquiler" &&
        showOn.toLowerCase() === "resultados alquiler"
      ) {
        console.log("true")
        return true
      }
      if (
        pathname.toLowerCase() === "alquiler-temporario" &&
        showOn.toLowerCase() === "resultados alquiler temporario"
      ) {
        console.log("true")
        return true
      }
      return Array.isArray(showOn)
        ? showOn.toLowerCase().includes(pathname.toLowerCase())
        : pathname.toLowerCase() === showOn.toLowerCase() || false
    }
  }

  const existSquarePopDetail = id => {
    const squarePopUps = [
      ...getActionByType(actions, getCodeFromType("PopUp Vertical")),
      ...getActionByType(actions, getCodeFromType("PopUp Cuadrado")),
      ...getActionByType(actions, getCodeFromType("PopUp Timer")),
      ...getActionByType(actions, getCodeFromType("PopUp Panoramico")),
    ]
    if (id) {
      for (const singleSquare of squarePopUps) {
        for (const dev of singleSquare.developments) {
          if (dev.toString() === id.toString()) {
            return true
          }
        }
        for (const prop of singleSquare.properties) {
          if (prop.toString() === id.toString()) {
            return true
          }
        }
      }
      return false
    } else {
      return false
    }
  }

  return checkPage() ? (
    <>
      <div className="article">
        <button
          type="button"
          id="squarePopUpBtn"
          data-bs-toggle="modal"
          data-bs-target="#squarePopUp"
        ></button>
        <div
          className="modal fade"
          id="squarePopUp"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className={`modal-dialog ${checkAlign()} ${checkFlat()}`}>
            {console.log(small_type)}
            <div
              className={`modal-content ${checkFlat()} ${
                small_type === "PUV" ? " verticalPopUp " : ""
              }`}
              style={{ backgroundImage: "url(" + image + ")" }}
            >
              <div
                className="modal-header"
                onClick={() => document.getElementById("closePopup").click()}
              >
                <i
                  className="icon-plus"
                  type="button"
                  role="button"
                  id="closePopup"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => document.getElementById("closePopup").click()}
                ></i>
              </div>
              <div
                className={`modal-body ${checkFlat()}`}
                onClick={() => goTo()}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null
}

export default SquarePopup

// Component usage
// <SquarePopup -> Square aligned center by default
//      align={'right' || 'left' || 'center'} -> Set the align for vertical banner
//      flat={true} -> Square flat with max-height
//      linkTo={'/emprendimientos/'} -> Set the link of image
//      showOn={'servicios' | ['venta','alquiler'] } /> -> Set route where shows the banner
