import { mediacoreApi } from "../mediacoreApi"


const serializeData = (data) => {
  const formData = new FormData()
  for (let key in data) {
    if (key === 'files') Array.from(data[key]).forEach((file) => formData.append(key, file))
    else
      formData.append(
        key,
        data[key] instanceof Blob || typeof data[key] === 'string' ? data[key] : JSON.stringify(data[key]),
      )
  }

  return formData
}

const jobsExtended = mediacoreApi.injectEndpoints({
  endpoints: builder => ({
    getJobs: builder.query({
      query: CLIENT_ID =>
        // `jobs/posiciones/activas/?client=${CLIENT_ID}`
        `jobs/posiciones/activas/?client=6`
    }),
    createApplication: builder.mutation({
      query: (data) => ({
        url: 'jobs/aplicaciones/aplicar/?client=6',
        method: 'POST',
        body: serializeData(data),
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useGetJobsQuery , useCreateApplicationMutation} = jobsExtended