import { useLocation } from "@reach/router"
import React, { useEffect } from "react"
import { getCodes } from "../../../../helpers/helper.actions"
import { informCustomEvent } from "../../../../helpers/helper.analytics"
import {
  getActionByType,
  getCodeFromType,
} from "../../../../helpers/helper.actions"

const FlapBtn = ({
  alignment = "left",
  linkTo,
  linkTxt,
  showOn,
  type,
  section,
  small_type,
  small_section,
  name,
  actions,
  properties,
  property,
  developments,
  development,
}) => {
  const location = useLocation()
  const pathname = location.pathname.replaceAll("/", "")

  const checkPage = () => {
    if (developments.length > 0 || properties.length > 0) {
      //Si es para una ficha en particular
      if (
        existFlapButtonDetail(property?.id) ||
        existFlapButtonDetail(development?.id)
      ) {
        if (
          properties.find(
            prop => prop?.toString() === property.id?.toString()
          ) ||
          developments.find(
            dev => dev?.toString() === development.id?.toString()
          )
        ) {
          return true
        }
      }
      return false
    } else {
      if (pathname === "" && showOn.toLowerCase() === "inicio") {
        return true
      }
      if (
        pathname.toLowerCase().includes("emprendimientos") &&
        pathname.length > 18 &&
        showOn.toLowerCase() === "ficha de emprendimiento" &&
        !existFlapButtonDetail(development?.id)
      ) {
        console.log("true")
        return true
      }
      if (
        pathname.toLowerCase().includes("propiedad") &&
        showOn.toLowerCase() === "ficha de la propiedad" &&
        !existFlapButtonDetail(property?.id)
      ) {
        console.log("true")
        return true
      }
      if (
        pathname.toLowerCase() === "venta" &&
        showOn.toLowerCase() === "resultados venta"
      ) {
        console.log("true")
        return true
      }
      if (
        pathname.toLowerCase() === "alquiler" &&
        showOn.toLowerCase() === "resultados alquiler"
      ) {
        console.log("true")
        return true
      }
      if (
        pathname.toLowerCase() === "alquiler-temporario" &&
        showOn.toLowerCase() === "resultados alquiler temporario"
      ) {
        console.log("true")
        return true
      }
      return Array.isArray(showOn)
        ? showOn.toLowerCase().includes(pathname.toLowerCase())
        : pathname.toLowerCase() === showOn.toLowerCase() || false
    }
  }

  // Calculate top by word length
  const calculatedTop =
    linkTxt?.split(" ")?.length > 2 ? "long-text" : "short-text"

  useEffect(() => {
    if (checkPage()) {
      informCustomEvent("SHOW_" + getCodes(small_type, showOn) + "_" + name)
    }
  }, [pathname])

  const existFlapButtonDetail = id => {
    const flapButtons = [
      ...getActionByType(actions, getCodeFromType("Solapa Flotante")),
    ]
    if (id) {
      for (const flapButton of flapButtons) {
        for (const dev of flapButton.developments) {
          if (dev.toString() === id.toString()) {
            return true
          }
        }
        for (const prop of flapButton.properties) {
          if (prop.toString() === id.toString()) {
            return true
          }
        }
      }
      return false
    } else {
      return false
    }
  }

  return (
    checkPage() && (
      <div
        id="flap-btn"
        className={`fixed-btn-wrapper ${alignment} ${calculatedTop}`}
      >
        <a
          href={linkTo}
          onClick={() =>
            informCustomEvent(
              "PUSH_" + getCodes(small_type, showOn) + "_" + name
            )
          }
          className="btn-link"
          target="_blank"
        >
          {linkTxt}
        </a>
      </div>
    )
  )
}

export default FlapBtn
